import { inject }                  from 'aurelia-framework';
import { ConstructionsRepository } from 'modules/management/concessions/constructions/services/repository';
import { ConstructionType }        from 'modules/management/models/construction-type';
import { LotConstructionType }     from 'modules/management/models/lot-construction-type';

@inject(ConstructionsRepository)
export class LotConstructionElementsFormSchema {

    /**
     * Constructor
     *
     * @param constructionsRepository
     */
    constructor(constructionsRepository) {
        this.constructionsRepository = constructionsRepository;
    }

    /**
     * Returns a new instance of the model
     *
     * @returns {{element_id: null, sub_element_ids: null}}
     */
    model() {
        return {
            element_id:      null,
            sub_element_ids: null,
        };
    }

    /**
     * Returns form schema
     *
     * @param viewModel
     *
     * @param readonly
     * @returns {*[]}
     */
    schema(viewModel, readonly = false) {
        this.elements_id = {
            type:         'select2',
            key:          'elements_id',
            label:        'form.field.element',
            size:         5,
            required:     false,
            remoteSource: () => this.constructionsRepository.allByType(Number(viewModel.lot_construction_type_id) === LotConstructionType.OA ? ConstructionType.OA_ELEMENTS : ConstructionType.OH_ELEMENTS),
            attributes:   {
                disabled: readonly,
            },
            observers:    [
                (newValue, oldValue) => {
                    if (newValue !== oldValue) {
                        this.sub_element_ids.instance.disable(!newValue);

                        if (newValue) {
                            this.sub_element_ids.instance.fetchData();
                            this.sub_element_ids.inputGroup.button.visible = true;
                        } else {
                            this.sub_element_ids.inputGroup.button.visible = false;
                        }
                    }
                },
            ],
            inputGroup:   {
                position: 'right',
                button:   {
                    visible: !readonly,
                    action:  () => {
                        this.addElementToModel(viewModel);
                        viewModel.orderElements();
                    },
                    icon:    {
                        class: 'icon-plus2',
                    },
                },
            },
        };

        this.divider = {
            type: 'divider',
            size: 1
        }

        this.sub_element_ids = {
            type:         'multiselect-native',
            key:          'sub_element_ids',
            label:        'form.field.sub-element',
            size:         6,
            required:     false,
            remoteSource: () => {
                return viewModel.filterModel.elements_id
                    ? this.constructionsRepository.subElements(viewModel.filterModel.elements_id)
                    : Promise.resolve([]);
            },
            attributes:   {
                disabled: true,
            },
            inputGroup:   {
                position: 'right',
                button:   {
                    visible: false,
                    action:  () => this.addSubElementsToModel(viewModel),
                    icon:    {
                        class: 'icon-plus2',
                    },
                },
            },
        };

        return [
            [this.elements_id, this.divider, this.sub_element_ids],
        ];
    }

    /**
     * Adds element to model
     *
     * @param viewModel
     * @param element
     * @param parentElementId
     */
    addElementToModel(viewModel, element = null, parentElementId = null) {
        element = element ?? this.elements_id.instance.selectedRecord();

        if (!viewModel.records.find(record => record.id === element.id)) {
            viewModel.records.push({
                ...element,
                construction_id:      element.id,
                construction_type_id: element.type_id,
                ...(parentElementId
                    ? {
                        parent_element_id: parentElementId,
                        sub_element_name:  element.name,
                    } : {
                        element_name: element.name,
                    }),
            });
        }
    }

    /**
     * Adds sub-element to model
     *
     * @param viewModel
     */
    addSubElementsToModel(viewModel) {
        const subElements   = this.sub_element_ids.instance.selectedItems();
        const parentElement = this.elements_id.instance.selectedRecord();

        this.addElementToModel(viewModel, parentElement)

        subElements.forEach(subElement => {
            this.addElementToModel(viewModel, subElement, parentElement.id)
        });

        viewModel.orderElements();
    }
}
