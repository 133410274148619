import { inject }           from 'aurelia-framework';
import { BaseRepository }   from 'modules/core/services/base-repository';
import { CustomHttpClient } from 'resources/services/custom-http-client';

@inject(CustomHttpClient)
export class LotConstructionsRepository extends BaseRepository {
    baseUrl = 'management/concessions/concessions/lot-constructions';

    /**
     * Creates a record
     *
     * @param data
     *
     * @param lotInterventionTypeId
     * @param lotConstructionTypeId
     * @returns {*}
     */
    create(data, lotInterventionTypeId, lotConstructionTypeId) {
        return this.httpClient.post(`management/concessions/concessions/${lotInterventionTypeId}/${lotConstructionTypeId}/lot-constructions`, data);
    }

    /**
     * Exports to pdf given some criteria
     *
     *
     * @param criteria
     * @returns {*}
     */
    exportToPdf(criteria) {
        return this.httpClient.rawPost(`${this.baseUrl}/export-to-pdf`, criteria)
            .then(response => {
                if (response.status !== 200) {
                    throw { status: false, message: 'Error' };
                } else {
                    return response.blob();
                }
            });
    }
}
