import { DialogService }                     from 'aurelia-dialog';
import { bindable, inject }                  from 'aurelia-framework';
import { I18N }                              from 'aurelia-i18n';
import { BaseListViewModel }                 from 'base-list-view-model';
import { LotConstructionElementsFormSchema } from 'modules/management/concessions/concessions-tree/lot-constructions/elements/form-schema';
import { DefaultDeleteResourceDialog }       from 'resources/elements/html-elements/dialogs/default-delete-resource-dialog';
import { AppContainer }                      from 'resources/services/app-container';

@inject(AppContainer, I18N, DialogService, LotConstructionElementsFormSchema)
export class ListLotConstructionElements extends BaseListViewModel {

    listingId                               = 'management-concessions-concessions-lot-constructions.oe-data-component-listing';
    lotConstructionElementsFormSchemaFormId = 'management-concessions-concessions-lot-constructions.oe-data-component-form';

    @bindable headerTitle = null;
    @bindable repository;
    @bindable datatable;
    @bindable model;

    records;
    elementGroups;

    /**
     * Constructor
     *
     * @param appContainer
     * @param i18N
     * @param dialogService
     * @param lotConstructionElementsFormSchema
     */
    constructor(appContainer, i18N, dialogService, lotConstructionElementsFormSchema) {
        super(appContainer);

        this.i18N                              = i18N;
        this.dialogService                     = dialogService;
        this.lotConstructionElementsFormSchema = lotConstructionElementsFormSchema;
    }

    /**
     * Handles canActivate event
     */
    canActivate() {
        return super.can([
            'management.specifications.bulletins.manage',
            'management.specifications.bulletins.view',
        ]);
    }

    /**
     * Handles activate event
     */
    activate(params) {
        super.activate();

        this.records                       = params.elements;
        this.lot_construction_type_id      = params.lot_construction_type_id;
        this.readonly                      = params.readonly;
        this.filterModel                   = this.lotConstructionElementsFormSchema.model(this);
        this.lotConstructionElementsSchema = this.lotConstructionElementsFormSchema.schema(this, this.readonly);

        this.setElementGroups();

        this.defineDatatable();
    }

    /**
     * Defines table columns
     */
    defineDatatable() {
        this.datatable = {
            repository:          {
                search:            (criteria) => this.search(criteria),
                destroySelected:   (ids) => this.destroySelected(ids),
                destroyedSelected: () => this.datatable.instance.reload(),
            },
            selectable:          true,
            showOptionsDropdown: true,
            showResultsPerPage:  false,
            showPagination:      false,
            actions:             [
                {
                    icon:    'icon-trash',
                    action:  (row, index) => this.destroyRecord(index),
                    tooltip: 'form.button.delete',
                    visible: (row) => !this.readonly,
                },
            ],
            options:             [],
            buttons:             [],
            sorting:             {
                column:    0,
                direction: 'asc',
            },
            columns:             [
                {
                    data:       'element_name',
                    title:      'form.field.element',
                    searchable: false,
                    orderable:  false,
                    type:       'processed',
                    processor:  (row) => {
                        return row.element_name
                            ? '<span class="icon-primitive-square mr-1" style="font-size: 10px"></span><span style="letter-spacing: .2px; font-size: 14px; font-weight: bold">' + row.element_name + '</span>'
                            : '<span style="margin-left: 10px"><span class="icon-tree5 mr-2 text-muted" style="font-size: 9px"></span>' + row.sub_element_name + '</span>';
                    },
                },
            ],
        };
    }

    /**
     * Performs a search given some criteria
     *
     * @param criteria
     *
     * @returns {*}
     */
    search(criteria) {
        let records = this.records;

        if (criteria.search.value !== null && criteria.search.value !== '') {
            let regExp = new RegExp(criteria.search.value, 'gi');

            records = this.records.filter((record) => {
                return ('' + record.name).match(regExp)
                    || ('' + record.type).match(regExp);
            });
        }

        return Promise.resolve({
            data:            records,
            recordsTotal:    this.records.length,
            recordsFiltered: records.length,
        });
    }

    /**
     * Destroy a selection of records
     *
     * @param selectedIds
     * @returns {*|Promise}
     */
    destroySelected(selectedIds) {
        if (!this.records) return Promise.resolve({});

        let message             = this.i18N.tr('messages.selected-deletion.succeeded');
        let someSpliced         = false;
        let triedDeletingParent = false;

        for (const id of selectedIds) {
            let index = this.records.findIndex(r => r.id === id);
            if (index !== -1) { // Record found
                let record = this.records[index];

                let spliceItOut = () => this.records.splice(index, 1); // splice works! :) thanks HFM

                // Is this record parent to some element group?
                if (this.elementGroups[record.id]) {
                    // Are all children going to be deleted too?
                    if (this.elementGroups[record.id].every(child => selectedIds.includes(child.id))) {
                        spliceItOut();
                        someSpliced = true;
                    } else {
                        triedDeletingParent = true
                    }
                } else {
                    spliceItOut();
                    someSpliced = true;
                }
            }
        }

        // No elements were spliced
        if (triedDeletingParent)
            message += '<br><b>' + this.i18N.tr('text.attention') + ':</b> '
                + this.i18N.tr('messages.cannot-delete-element-that-has-sub-elements-selected');
        if (!someSpliced)
            message = this.i18N.tr('messages.cannot-delete-element-that-has-sub-elements-selected');

        // Update element groups with most recent records
        this.setElementGroups();

        return Promise.resolve({
            message: message,
            status:  someSpliced,
        })
    }

    /**
     * Requests confirmation to destroy the selected record
     *
     * @param index
     */
    destroyRecord(index) {
        this.dialogService.open({
                viewModel: DefaultDeleteResourceDialog,
                model:     {},
            })
            .whenClosed((response) => {
                if (!response.wasCancelled) {
                    if (this.elementGroups[this.records[index].id]) {
                        // If record is a parent, it can't be deleted
                        this.appContainer.notifier['dangerNotice'](this.i18N.tr('messages.cannot-delete-element-that-has-sub-elements-selected'));
                    } else {
                        this.destroy(index).then((response) => {
                            this.appContainer.notifier['successNotice'](response.message);

                            this.datatable.instance.reload();
                        });
                    }
                }
            });
    }

    /**
     * Destroy the selected record
     *
     * @param index
     */
    destroy(index) {
        return new Promise((resolve, reject) => {
            this.records.splice(index, 1);

            this.records.forEach((record, number) => record.code = number + 1);

            resolve({
                status:  true,
                message: this.i18N.tr('messages.selected-deletion.succeeded'),
            });
        });
    }

    setElementGroups() {
        this.elementGroups = {};
        let parents        = [];

        this.records.forEach((item) => {
            if (item.parent_element_id) {
                if (!this.elementGroups[item.parent_element_id]) {
                    this.elementGroups[item.parent_element_id] = [];
                }
                this.elementGroups[item.parent_element_id].push(item);
            } else {
                parents.push(item);
            }
        });

        return parents;
    }

    /**
     * Orders elements in records by ensuring each parent element
     * is immediately followed by its sub-elements, sorted alphabetically.
     */
    orderElements() {
        this.elementGroups = {};
        let parents        = this.setElementGroups();

        let sortedRecords = [];

        parents.forEach((parent) => {
            // Push parent first
            sortedRecords.push(parent);

            // Push children below parent
            let children = this.elementGroups[parent.construction_id];

            if (children) {
                // Order children alphabetically and push
                sortedRecords.push(...children.sort());
            }
        });

        // Mutate the existing array instead of reassigning -- splice works! :)
        this.records.splice(0, this.records.length, ...sortedRecords);

        this.datatable.instance.reload();
    }

}
